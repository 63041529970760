import React, { useState } from "react";
import {
    ListItem,
    Checkbox,
    IconButton,
    ListItemText,
    ListItemSecondaryAction,
    Typography,
    Chip
} from "@material-ui/core";
import { Link } from 'react-router-dom';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';

import { Redirect, useParams } from 'react-router';
import AddIcon from '@material-ui/icons/Add';


import { checkTarea } from '../../../services/tarea'
import { Delete } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import { deleteTarea } from "../../../services/tarea"
import Swal from "sweetalert2";

const DiasTranscurridos = ({dias}) => {
    
    return (
        
        (
            dias !== undefined
            ?
            <Chip size="small" color="primary" style={{marginLeft: 10}} label={ `${parseInt(dias)} d`} variant="outlined" />
            :
            <></>
        )
        
    );
}


const TodoListItem = React.memo(
    ({ id, text, fecha, fecha_mostrar, divider, checked, showLink = true, dias_transcurridos }) => {
        checked = (checked == 0 ? false : true)
        const [estado, setEstado] = useState(checked)
        const [esBaja, setEsBaja] = useState(false)
        const { id: padreId } = useParams();

        const usuarioSeleccionado = useSelector(state => {
            return selectUsuarioSeleccionado(state);
        });

        const onCheckBoxToggle = (tarea_id, is_checked) => {
            setEstado(is_checked)

            checkTarea(tarea_id, is_checked).then((response) => {
                console.log(response)
            });
        }

        const handleEliminarTarea = (id) => {
            Swal.fire({
                title: '¿Realmente deseas eliminar la tarea?',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText: `Cancelar`,
                confirmButtonColor: '#afcb0d',
                cancelButtonColor: '#a5a5a5',
                icon: 'warning',
                reverseButtons: true
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    setEsBaja(true)
                    const usuario_id = usuarioSeleccionado.id
            
                    deleteTarea( id, usuario_id).then((response) => {
                        console.log(response)
                        // response && setTodos(response.tareas)
                    })
                } else if (result.isDenied) {
                //   Swal.fire('Changes are not saved', '', 'info')
                }
              })

            
        
        }

        const toTimestamp = (strDate) => {
            var arr = strDate.split(/[- :]/)
            let date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);

            var datum = date.getTime();
            return datum/1000;
         }

        const handleAddTarea = () => {
            console.log("Add tarea a partir de tarea")
        }
    
        return (

            <>
            {
                esBaja === false

                ?
            
                < ListItem divider={divider} >
                    
                    <Checkbox onClick={(event) => onCheckBoxToggle(id, event.target.checked)} checked={estado} disableRipple />
                    {
                        estado == 1 ?
                            <ListItemText 
                                primary={
                                    <Typography style={{ textDecoration: "line-through" }} >
                                        {text}
                                    </Typography >
                                } 
                                secondary={
                                    <>
                                        {fecha_mostrar}
                                        { showLink ? <DiasTranscurridos dias={dias_transcurridos} /> : <></> }
                                    </>
                                } 
                            />
                            :
                            <ListItemText 
                                primary={text} 
                                secondary={
                                    <>
                                        {fecha_mostrar}
                                        { showLink ? <DiasTranscurridos dias={dias_transcurridos} /> : <></> }
                                    </>
                                } 
                            />

                    }
                    
                    
                    {
                        showLink ?

                            <ListItemSecondaryAction>
                                <IconButton aria-label="Abrir Todo" component={Link} to={`/tarea/detalle/${id}`}>
                                    <ChevronRightIcon />
                                </IconButton>
                            </ListItemSecondaryAction>

                            :

                            <ListItemSecondaryAction>
                                <IconButton color="secondary"  aria-label="Añadir a partir de esta tarea" component={Link} to={`/tarea/add_desde/${padreId}/${toTimestamp(fecha)}`}>
                                     <AddIcon  />
                                </IconButton>
                                <IconButton aria-label="Eliminar Todo" onClick={ () => handleEliminarTarea(id) }>
                                     <DeleteTwoToneIcon style={{color: 'red'}} />
                                </IconButton>
                            </ListItemSecondaryAction>
                    }
                    {/* <ListItemSecondaryAction>
                    <IconButton aria-label="Delete Todo" onClick={onButtonClick}>
                        <DeleteOutlined />
                    </IconButton>
                </ListItemSecondaryAction> */}
                </ListItem >

                :

                <></>
            }
            </>
        )
    }
);

export default TodoListItem;
