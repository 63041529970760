import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import SiguienteSemanaContent from './components/SiguienteSemanaContent';

const SiguienteSemana = () => {

    return (
        <>

            <TemplateDashboard showAdd={true}>
                <SiguienteSemanaContent />
            </TemplateDashboard>

        </>
    )
};

export default SiguienteSemana;