import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import ArchivadasContent from './components/ArchivadasContent';

const Archivadas = () => {

    return (
        <>

            <TemplateDashboard showAdd={true}>
                <ArchivadasContent />
            </TemplateDashboard>

        </>
    )
};

export default Archivadas;