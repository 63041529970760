import Login from './pages/login';
import Logout from './pages/logout';
import Home from './pages/home';
import SiguienteSemana from './pages/siguienteSemana';

// import Sensores from './pages/sensores';
// import Notas from './pages/notas';
import TareaAdd from './pages/tarea/add'
import NotFound from './pages/notFound';

import {
  BrowserRouter,
  Link,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import React from 'react';

import { useSelector } from "react-redux";

import {
  selectUsuarioSeleccionado,
} from "./redux/reducer/loginReducer";

import TareaDetalle from './pages/tarea/detalle'
import Archivadas from './pages/archivadas';

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

const RouteHome = () => {
  return (
    <Route component={Home} path="/home"></Route>
  );
}
const RouteArchivadas = () => {
  return (
    <Route component={Archivadas} path="/archivo"></Route>
  );
}
const RouteSiguienteSemana = () => {
  return (
    <Route component={SiguienteSemana} path="/siguiente_semana"></Route>
  );
}
const RouteTareaAdd = () => {
  return (
    <>
      <Route component={TareaAdd} path="/tarea/add/:id"></Route>
    </>
  );
}
const RouteTareaAddDesde = () => {
  return (
    <>
      <Route component={TareaAdd} path="/tarea/add_desde/:id/:fecha"></Route>
    </>
  );
}
const RouteTareaDetalle = () => {
  return (
    <>

      <Route component={TareaDetalle} path="/tarea/detalle/:id"></Route>
    </>
  );
}


export default function App(props) {
  const usuarioSeleccionado = useSelector(state => {
    return selectUsuarioSeleccionado(state);
  });

  return (

    <BrowserRouter>
      <Switch>

        {/* ZONA PUBLICA */}
        <Route component={Login} path="/login"></Route>
        <Route component={Logout} path="/logout"></Route>
        <Route exact path="/">
          <div className="container">
            {
              usuarioSeleccionado ?
                <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
                :
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
            <Link className="btn btn-secondary btn-lg btn-block" to="/login">Login</Link>
            <Link className="btn btn-secondary btn-lg btn-block" to="/home">Home</Link>
          </div>
        </Route>

        {/* ZONA PRIVADA */}
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/tarea/add' component={RouteTareaAdd} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/tarea/add_desde' component={RouteTareaAddDesde} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/home' component={RouteHome} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/archivo' component={RouteArchivadas} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/siguiente_semana' component={RouteSiguienteSemana} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/tarea/detalle/:id' component={RouteTareaDetalle} />

        {/* PAGINA NO ENCONTRADA */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>

  )
}