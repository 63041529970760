import React, { useEffect } from 'react'
import TemplateDashboard from '../../../components/TemplateDashboard'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import {
    Box, TextField,
} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";

import { Redirect, useParams } from 'react-router';
import { duplicarTarea } from '../../../services/tarea';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 90
    },
    textField: {
    },
    keyboardDatePicker: {
    },
    formulario: {
        margin: 8
    },
}));


export default function TareaDuplicar({setMostrarFormTareaDuplicar}) {
    const { 
        id: tareaId,
    } = useParams();
    const classes = useStyles();
    let history = useHistory();

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    const [descripcion, setDescripcion] = React.useState('');
    const [descripcionError, setDescripcionError] = React.useState('');

    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [dateError, setDateError] = React.useState('');

    const [selectedTime, setSelectedTime] = React.useState(new Date());
    const [timeError, setTimeError] = React.useState('');

    // useEffect(() => {

    //     if (tareaId != '-1') {
    //         getTareas({ tarea_id: tareaId }).then((response) => {
    //             response && setTareaPadre(response.tarea)
    //         });
    //     }

    // }, [tareaId])

    // const calcularDiasBase = () => {
    //     const fecha1 = new Date(tareaPadre.fecha).getTime(); 
    //     const fecha2 = new Date(fechaReferencia * 1000).getTime()
    //     var result = (fecha2 - fecha1) / (1000 * 60 * 60 * 24); //divido la resta de las dos fechas entre 1000milisegundos * 60segundos * 60minutos * 24horas , obteniendo como resultado 1dia de diferencia
        
    //     if(!isNaN( result ))
    //         return result
    //     else 
    //         return 0
    // }

    // useEffect(() => {
    //     // console.log('ID TAREA PADRE: ' + tareaId)
        
    //     setDiasBase(tareaPadre && calcularDiasBase())

    //     if(fechaReferencia != null) {
    //         let TuFecha = new Date(fechaReferencia * 1000)

    //         let dateOffset = (24 * 60 * 60 * 1000) * dias;
    //         switch(sufijoDias) {
    //             case '-':
    //                 TuFecha.setTime(TuFecha.getTime() - dateOffset);
    //                 break;
    //             case 'hoy':
    //                 TuFecha = new Date()
    //                 break;
    //             default: 
    //                 TuFecha.setTime(TuFecha.getTime() + dateOffset);
    //         }
            
    //         let dateStr =
    //         TuFecha.getFullYear() + "-" +
    //             ("00" + (TuFecha.getMonth() + 1)).slice(-2) + "-" +
    //             ("00" + TuFecha.getDate()).slice(-2) + " " +
    //             ("00" + TuFecha.getHours()).slice(-2) + ":" +
    //             ("00" + TuFecha.getMinutes()).slice(-2) + ":" +
    //             ("00" + TuFecha.getSeconds()).slice(-2);

    //         setFechaFinal(dateStr);

    //         dateStr =
    //             ("00" + TuFecha.getDate()).slice(-2) + "/" +
    //             ("00" + (TuFecha.getMonth() + 1)).slice(-2) + "/" +
    //             TuFecha.getFullYear() + "";
    //         setFechaMostrarFinal(dateStr);

    //     } else {
    //         if (tareaPadre != null) {
    //             let TuFecha = new Date(tareaPadre.fecha.replace(/-/g, "/"));
    //             let dateOffset = (24 * 60 * 60 * 1000) * dias;
    //             switch(sufijoDias) {
    //                 case '-':
    //                     TuFecha.setTime(TuFecha.getTime() - dateOffset);
    //                     break;
    //                 case 'hoy':
    //                     TuFecha = new Date()
    //                     break;
    //                 default: 
    //                     TuFecha.setTime(TuFecha.getTime() + dateOffset);
    //             }
    //             // if (sufijoDias == '-') {
    //             //     TuFecha.setTime(TuFecha.getTime() - dateOffset);
    //             // } else {
    //             //     TuFecha.setTime(TuFecha.getTime() + dateOffset);
    //             // }

    //             let dateStr =
    //                 TuFecha.getFullYear() + "-" +
    //                 ("00" + (TuFecha.getMonth() + 1)).slice(-2) + "-" +
    //                 ("00" + TuFecha.getDate()).slice(-2) + " " +
    //                 ("00" + TuFecha.getHours()).slice(-2) + ":" +
    //                 ("00" + TuFecha.getMinutes()).slice(-2) + ":" +
    //                 ("00" + TuFecha.getSeconds()).slice(-2);

    //             setFechaFinal(dateStr);

    //             dateStr =
    //                 ("00" + TuFecha.getDate()).slice(-2) + "/" +
    //                 ("00" + (TuFecha.getMonth() + 1)).slice(-2) + "/" +
    //                 TuFecha.getFullYear() + "";
    //             setFechaMostrarFinal(dateStr);

    //         }
    //     }

    // }, [tareaPadre, dias, sufijoDias, fechaReferencia])

    const handleDescripcionChange = (event) => {
        setDescripcion(event.target.value);
    };
    // const handleDiasChange = (event) => {
    //     setDias(event.target.value);
    // };
    // const handleSufijoDiasChange = (event) => {
    //     setSufijoDias(event.target.value);
    // };
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleTimeChange = (date) => {
        setSelectedTime(date);
    };
    const handleSubmit = () => {
        if (selectedDate === '' || selectedDate == null) {
            setDateError("Fecha obligatoria")
            return null;
        } else {
            setDateError('')
        }
        if (selectedTime === '') {
            setTimeError("Hora obligatoria")
            return null;
        } else {
            setTimeError('')
        }
        Swal.fire({
            title: '¿Realmente deseas duplicar la tarea?',
            showCancelButton: true,
            confirmButtonText: 'Duplicar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                const usuario_id = usuarioSeleccionado.id

                duplicarTarea( tareaId, descripcion, selectedDate, selectedTime, usuario_id).then((response) => {
                    
                    history.push("/home");
                    
                })

            } else if (result.isDenied) {
            //   Swal.fire('Changes are not saved', '', 'info')
            }
          })

        

    //     // Se para ejecución si falta un campo obligatorio
    //     if (descripcion == '' || selectedDate == '' || selectedDate == null || selectedTime == '' || dias == '') {
    //         return null
    //     }
        
    //     if( isNaN(diasBase) ) { setDiasBase(0) }

    //     if (tareaId != '-1') {

    //         insertTarea(
    //             descripcion,
    //             fechaFinal,
    //             usuarioSeleccionado.id,
    //             tareaId, // ID del padre
    //             diasBase + dias,
    //             sufijoDias
    //         )

    //     } else {

    //         insertTarea(
    //             descripcion,
    //             formatDateTime(selectedDate, selectedTime),
    //             usuarioSeleccionado.id,
    //             tareaId,
    //             dias,
    //             sufijoDias
    //         )
    //     }
    //     history.goBack()
    //     // console.log('Se envia')
    }

    return (
        <TemplateDashboard title="Añade la tarea" showBack={true} tareaId={tareaId}>
            <div className={classes.root}>
                <div>
                    
                    <form className={classes.formulario} noValidate autoComplete="off">
                        {/* <Typography variant="h5" align="left" component="h3" gutterBottom style={{ marginBottom: 20 }}>
                            Nueva tarea
                        </Typography> */}

                        {
                            <>
                                <TextField
                                    id="standard-full-width"
                                    label="Descripción de la tarea"
                                    // style={{ margin: 8 }}
                                    placeholder="Introduce la descripción de la tarea"
                                    fullWidth
                                    margin="normal"
                                    value={descripcion}
                                    onChange={handleDescripcionChange}
                                    variant="outlined"
                                    error={descripcionError === '' ? false : true}
                                    // helperText={descripcionError == '' ? null : descripcionError}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    multiline
                                />
                                < MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        // style={{ margin: 8 }}
                                        variant="outlined"
                                        format="dd/MM/yyyy"
                                        defaultValue={new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear()}
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Fecha"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        error={dateError == '' ? false : true}
                                        // helperText={dateError == '' ? null : dateError}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Selecciona una fecha',
                                        }}
                                        fullWidth
                                        inputVariant="outlined"
                                    />
                                    <KeyboardTimePicker
                                        margin="normal"
                                        // style={{ margin: 8 }}
                                        format="HH:mm"
                                        variant="outlined"
                                        id="time-picker"
                                        label="Hora"
                                        value={selectedTime}
                                        onChange={handleTimeChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Selecciona una hora',
                                        }}
                                        fullWidth
                                        inputVariant="outlined"
                                        error={timeError == '' ? false : true}
                                    // helperText={timeError == '' ? null : timeError}

                                    />

                                </MuiPickersUtilsProvider>
                            </>
                        }

                        <Box mt={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button color="primary" style={{ marginRight: '30px' }} onClick={() => setMostrarFormTareaDuplicar(false)}>Cancelar</Button>
                            <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                                Crear duplicado
                            </Button>
                        </Box>
                    </form>
                </div>
            </div>
        </TemplateDashboard >
    )
}
