export function formatDate(date, time) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
export function formatTime(date) {
    var d = new Date(date),
        hours = '' + (d.getHours()),
        minutes = '' + d.getMinutes();

    if (hours.length < 2)
        hours = '0' + hours;
    if (minutes.length < 2)
        minutes = '0' + minutes;

    return [hours, minutes].join(':');
}

export function formatDateTime(date, time) {
    return formatDate(date) + " " + formatTime(time);
}