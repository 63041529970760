import React, { useEffect } from 'react'
import TemplateDashboard from '../../../components/TemplateDashboard'

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {
    Typography,
    Box,
    FormControl,
    InputLabel, Select, MenuItem
} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { useHistory } from "react-router-dom";

import { insertTarea } from '../../../services/tarea'
import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";

import { formatDateTime } from '../../../utils/dateTime'
import { Redirect, useParams } from 'react-router';

import { getTareas } from '../../../services/tarea'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 90
        // display: 'flex',
        // flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    keyboardDatePicker: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    formulario: {
        margin: 8
    },
}));


export default function TareaAdd() {
    const { id: tareaId, fecha: fechaReferencia } = useParams();
    const classes = useStyles();
    let history = useHistory();

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    const [descripcion, setDescripcion] = React.useState('');
    const [descripcionError, setDescripcionError] = React.useState('');

    const [dias, setDias] = React.useState(1);
    const [diasBase, setDiasBase] = React.useState(0);
    const [diasError, setDiasError] = React.useState('');

    const [sufijoDias, setSufijoDias] = React.useState('+');

    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [dateError, setDateError] = React.useState('');

    const [selectedTime, setSelectedTime] = React.useState(new Date());
    const [timeError, setTimeError] = React.useState('');

    const [fechaFinal, setFechaFinal] = React.useState('');
    const [fechaMostrarFinal, setFechaMostrarFinal] = React.useState('');

    const [tareaPadre, setTareaPadre] = React.useState(null);

    useEffect(() => {

        if (tareaId != '-1') {
            getTareas({ tarea_id: tareaId }).then((response) => {
                response && setTareaPadre(response.tarea)
            });
        }

    }, [tareaId])

    const calcularDiasBase = () => {
        const fecha1 = new Date(tareaPadre.fecha).getTime(); 
        const fecha2 = new Date(fechaReferencia * 1000).getTime()
        var result = (fecha2 - fecha1) / (1000 * 60 * 60 * 24); //divido la resta de las dos fechas entre 1000milisegundos * 60segundos * 60minutos * 24horas , obteniendo como resultado 1dia de diferencia
        
        if(!isNaN( result ))
            return result
        else 
            return 0
    }

    useEffect(() => {
        // console.log('ID TAREA PADRE: ' + tareaId)
        
        setDiasBase(tareaPadre && calcularDiasBase())

        if(fechaReferencia != null) {
            let TuFecha = new Date(fechaReferencia * 1000)

            let dateOffset = (24 * 60 * 60 * 1000) * dias;
            switch(sufijoDias) {
                case '-':
                    TuFecha.setTime(TuFecha.getTime() - dateOffset);
                    break;
                case 'hoy':
                    TuFecha = new Date()
                    break;
                default: 
                    TuFecha.setTime(TuFecha.getTime() + dateOffset);
            }
            
            let dateStr =
            TuFecha.getFullYear() + "-" +
                ("00" + (TuFecha.getMonth() + 1)).slice(-2) + "-" +
                ("00" + TuFecha.getDate()).slice(-2) + " " +
                ("00" + TuFecha.getHours()).slice(-2) + ":" +
                ("00" + TuFecha.getMinutes()).slice(-2) + ":" +
                ("00" + TuFecha.getSeconds()).slice(-2);

            setFechaFinal(dateStr);

            dateStr =
                ("00" + TuFecha.getDate()).slice(-2) + "/" +
                ("00" + (TuFecha.getMonth() + 1)).slice(-2) + "/" +
                TuFecha.getFullYear() + "";
            setFechaMostrarFinal(dateStr);

        } else {
            if (tareaPadre != null) {
                let TuFecha = new Date(tareaPadre.fecha.replace(/-/g, "/"));
                let dateOffset = (24 * 60 * 60 * 1000) * dias;
                switch(sufijoDias) {
                    case '-':
                        TuFecha.setTime(TuFecha.getTime() - dateOffset);
                        break;
                    case 'hoy':
                        TuFecha = new Date()
                        break;
                    default: 
                        TuFecha.setTime(TuFecha.getTime() + dateOffset);
                }
                // if (sufijoDias == '-') {
                //     TuFecha.setTime(TuFecha.getTime() - dateOffset);
                // } else {
                //     TuFecha.setTime(TuFecha.getTime() + dateOffset);
                // }

                let dateStr =
                    TuFecha.getFullYear() + "-" +
                    ("00" + (TuFecha.getMonth() + 1)).slice(-2) + "-" +
                    ("00" + TuFecha.getDate()).slice(-2) + " " +
                    ("00" + TuFecha.getHours()).slice(-2) + ":" +
                    ("00" + TuFecha.getMinutes()).slice(-2) + ":" +
                    ("00" + TuFecha.getSeconds()).slice(-2);

                setFechaFinal(dateStr);

                dateStr =
                    ("00" + TuFecha.getDate()).slice(-2) + "/" +
                    ("00" + (TuFecha.getMonth() + 1)).slice(-2) + "/" +
                    TuFecha.getFullYear() + "";
                setFechaMostrarFinal(dateStr);

            }
        }

    }, [tareaPadre, dias, sufijoDias, fechaReferencia])

    const handleDescripcionChange = (event) => {
        setDescripcion(event.target.value);
    };
    const handleDiasChange = (event) => {
        setDias(event.target.value);
    };
    const handleSufijoDiasChange = (event) => {
        setSufijoDias(event.target.value);
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleTimeChange = (date) => {
        setSelectedTime(date);
    };
    const handleSubmit = () => {
        // Aviso de los campos obligatorios
        if (descripcion == '') {
            setDescripcionError("Descripcion obligatoria")
        } else {
            setDescripcionError('')
        }
        if (selectedDate == '' || selectedDate == null) {
            setDateError("Fecha obligatoria")
        } else {
            setDateError('')
        }
        if (selectedTime == '') {
            setTimeError("Hora obligatoria")
        } else {
            setTimeError('')
        }
        if (dias == '' || dias == '0') {
            setDiasError("Dias obligatorios")
        } else {
            setDiasError('')
        }

        // Se para ejecución si falta un campo obligatorio
        if (descripcion == '' || selectedDate == '' || selectedDate == null || selectedTime == '' || dias == '') {
            return null
        }
        
        if( isNaN(diasBase) ) { setDiasBase(0) }

        if (tareaId != '-1') {

            insertTarea(
                descripcion,
                fechaFinal,
                usuarioSeleccionado.id,
                tareaId, // ID del padre
                diasBase + dias,
                sufijoDias
            )

        } else {

            insertTarea(
                descripcion,
                formatDateTime(selectedDate, selectedTime),
                usuarioSeleccionado.id,
                tareaId,
                dias,
                sufijoDias
            )
        }
        history.goBack()
        // console.log('Se envia')
    }

    return (
        <TemplateDashboard title="Añade la tarea" showBack={true} tareaId={tareaId}>
            <div className={classes.root}>
                <div>

                    <form className={classes.formulario} noValidate autoComplete="off">
                        {/* <Typography variant="h5" align="left" component="h3" gutterBottom style={{ marginBottom: 20 }}>
                            Nueva tarea
                        </Typography> */}
                        <TextField
                            id="standard-full-width"
                            label="Descripción de la tarea"
                            // style={{ margin: 8 }}
                            placeholder="Introduce la descripción de la tarea"
                            fullWidth
                            margin="normal"
                            value={descripcion}
                            onChange={handleDescripcionChange}
                            variant="outlined"
                            error={descripcionError == '' ? false : true}
                            // helperText={descripcionError == '' ? null : descripcionError}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            multiline
                        />

                        {
                            tareaId === '-1'

                                ?

                                < MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        // style={{ margin: 8 }}
                                        variant="outlined"
                                        format="dd/MM/yyyy"
                                        defaultValue={new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear()}
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Fecha"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        error={dateError == '' ? false : true}
                                        // helperText={dateError == '' ? null : dateError}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Selecciona una fecha',
                                        }}
                                        fullWidth
                                        inputVariant="outlined"
                                    />
                                    <KeyboardTimePicker
                                        margin="normal"
                                        // style={{ margin: 8 }}
                                        format="HH:mm"
                                        variant="outlined"
                                        id="time-picker"
                                        label="Hora"
                                        value={selectedTime}
                                        onChange={handleTimeChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Selecciona una hora',
                                        }}
                                        fullWidth
                                        inputVariant="outlined"
                                        error={timeError == '' ? false : true}
                                    // helperText={timeError == '' ? null : timeError}

                                    />

                                </MuiPickersUtilsProvider>

                                :

                                <>
                                    <Grid container alignItems="flex-start" spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="standard-full-width"
                                                label="Dias"
                                                // style={{ margin: 8 }}
                                                placeholder="Dias"
                                                margin="normal"
                                                value={dias}
                                                onChange={handleDiasChange}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                fullWidth
                                                error={diasError == '' ? false : true}
                                            // helperText={diasError == '' ? null : diasError}

                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                style={{ marginTop: 16 }}
                                            >
                                                <InputLabel id="demo-simple-select-label">Días</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={sufijoDias}
                                                    label="Dias"
                                                    onChange={handleSufijoDiasChange}
                                                    fullWidth
                                                >
                                                    <MenuItem value={'-'}>Antes</MenuItem>
                                                    <MenuItem value={'hoy'}>Hoy</MenuItem>
                                                    <MenuItem value={'+'}>Después</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography display="inline" style={{ fontWeight: 'bold' }}>Fecha:</Typography> {fechaMostrarFinal}
                                    </Grid>
                                </>
                        }

                        <Box mt={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button color="primary" style={{ marginRight: '30px' }} onClick={() => history.goBack()}>Cancelar</Button>
                            <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                                Guardar
                            </Button>
                        </Box>
                    </form>
                </div>
            </div>
        </TemplateDashboard >
    )
}
