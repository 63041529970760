import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router';
import TemplateDashboard from '../../../components/TemplateDashboard';

import noResult from '../../../assets/img/no-result.png'
import { PRIMARY_COLOR } from '../../../constants/colores'
import { deleteTarea, duplicarTarea, getTareas } from '../../../services/tarea';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import {
    makeStyles,
    Paper,
    Typography,
    Box,
    ListItemSecondaryAction,
    IconButton
} from '@material-ui/core';

import Loading from '../../../components/Loading'
import TodoList from '../../../components/TodoList'
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import TareaDuplicar from './duplicar';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
}));

export default function TareaDetalle() {
    const classes = useStyles();
    let { id: tarea_id } = useParams();

    const [tarea, setTarea] = useState({})
    const [tareasDependientes, setTareasDependientes] = useState({})
    const [loadingTarea, setLoadingTarea] = useState(false)
    const [mostrarFormTareaDuplicar, setMostrarFormTareaDuplicar] = useState(false)
    
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    let history = useHistory();

    useEffect(() => {
        setLoadingTarea(true)
        getTareas({ tarea_id }).then((response) => {

            response && setTarea(response.tarea)
            response && setTareasDependientes(response.tareas_dependientes)
            setLoadingTarea(false)
        });

    }, [])

    const ContenidoTareasDependientes = () => {
        return (
            tareasDependientes.length > 0 ?
                <>
                    <Typography style={{ margin: 16 }} variant="h6" gutterBottom component="div">
                        Tareas dependientes
                    </Typography>

                    <TodoList
                        tarea_padre_id={tarea_id}
                        items={tareasDependientes}
                    />
                </>
                :
                <></>
        )
    }

    const handleEliminarTarea = (id) => {
        Swal.fire({
            title: '¿Realmente deseas eliminar la tarea?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                console.log(id)
                const usuario_id = usuarioSeleccionado.id

                deleteTarea( id, usuario_id).then((response) => {
                    
                    history.push("/home");
                    
                })

            } else if (result.isDenied) {
            //   Swal.fire('Changes are not saved', '', 'info')
            }
          })

        
        
    }

    const handleDuplicarTarea = (id) => {
        setMostrarFormTareaDuplicar(true)
        // Swal.fire({
        //     title: '¿Realmente deseas duplicar la tarea?',
        //     showCancelButton: true,
        //     confirmButtonText: 'Duplicar',
        //     cancelButtonText: `Cancelar`,
        //     confirmButtonColor: '#afcb0d',
        //     cancelButtonColor: '#a5a5a5',
        //     icon: 'warning',
        //     reverseButtons: true
        //   }).then((result) => {
        //     /* Read more about isConfirmed, isDenied below */
        //     if (result.isConfirmed) {
                
        //         console.log(id)
        //         const usuario_id = usuarioSeleccionado.id

        //         duplicarTarea( id, usuario_id).then((response) => {
                    
        //             history.push("/home");
                    
        //         })

        //     } else if (result.isDenied) {
        //     //   Swal.fire('Changes are not saved', '', 'info')
        //     }
        //   })
    }

    return (
        <TemplateDashboard showBack={true} showAdd={true} tareaId={tarea_id}>

            <div className={classes.container}>

                {
                    mostrarFormTareaDuplicar == 1 
                    ?

                    <TareaDuplicar 
                        tareaId={tarea_id}
                        setMostrarFormTareaDuplicar={setMostrarFormTareaDuplicar} 
                    />

                    :
                    <>
                    <Paper style={{ margin: 16, padding: 16, display: 'flex', justifyContent: 'space-between' }}>

                        {
                            (
                                loadingTarea ?
                                    <Loading />
                                    :
                                    tarea.descripcion 
                            )
                        }

                        <div>
                            <IconButton color="secondary" style={{ padding: "0", marginRight: 20 }} aria-label="Duplicar" onClick={ () => handleDuplicarTarea(tarea_id) }>
                                <FileCopyIcon />
                            </IconButton>

                            <IconButton style={{ padding: "0" }} aria-label="Eliminar Todo" onClick={ () => handleEliminarTarea(tarea_id) }>
                                <DeleteTwoToneIcon style={{color: 'red'}} />
                            </IconButton>

                        </div>
                    </Paper>

                    <ContenidoTareasDependientes />
                    </>
                }
            </div>
        </TemplateDashboard>
    )
}
