import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
import moment from "moment";

export async function insertTarea(descripcion, fecha, usuario_id, tarea_padre_id, dias, sufijoDias) {
    try {
        var config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        };

        var params = new URLSearchParams();
        params.append('descripcion', descripcion);
        params.append('fecha', fecha);
        params.append('usuario_id', usuario_id);
        params.append('tarea_padre_id', tarea_padre_id);
        params.append('dias', dias );
        params.append('sufijoDias', sufijoDias );

        const response = await axios.post(BASE_URL_SERVICE + 'tarea/addtodo', params, config);

        return response;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

export async function checkTarea(tarea_id, is_checked) {
    try {
        var config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        };

        var params = new URLSearchParams();
        params.append('tarea_id', tarea_id);
        params.append('is_checked', is_checked);

        const response = await axios.post(BASE_URL_SERVICE + 'tarea/checktodo', params, config);

        return response;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

export async function deleteTarea(tarea_id, usuario_id) {
    try {
        var config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        };

        var params = new URLSearchParams();
        params.append('tarea_id', tarea_id);
        params.append('usuario_id', usuario_id);

        const response = await axios.post(BASE_URL_SERVICE + 'tarea/deletetodo', params, config);

        return response;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

export async function duplicarTarea(tarea_id, descripcion, fecha, hora, usuario_id) {
    try {
        var config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        };

        var params = new URLSearchParams();
        params.append('tarea_id', tarea_id);
        params.append('usuario_id', usuario_id);
        params.append('descripcion', descripcion);
        params.append('fecha', dateToMysql(fecha) + ' ' + timeToMysql(hora));
        params.append('usuario_id', usuario_id);

        const response = await axios.post(BASE_URL_SERVICE + 'tarea/duplicartodo', params, config);

        return response;
    } catch (error) {
        return undefined;
        // throw error;
    }

}


export async function getTareas({ usuario_id, tarea_padre_id = -1, tarea_id = -1, es_archivo = 0 }) {
    try {
        var config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'text/json'
            }
        };

        const response = await axios.get(BASE_URL_SERVICE + 'tarea', {
            params: {
                usuario_id,
                tarea_padre_id,
                tarea_id,
                es_archivo
            }
        }, config);

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

export async function getTareasSiguienteSemana({ usuario_id }) {
    try {
        var config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'text/json'
            }
        };

        const response = await axios.get(BASE_URL_SERVICE + 'tarea/siguiente_semana', {
            params: {
                usuario_id,
            }
        }, config);

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

export const dateToMysql = (fecha) => {
    if(fecha !== null && fecha !== undefined && fecha !== '0000-00-00 00:00:00') {
        try{
            return moment(fecha).format('YYYY-MM-DD')
        } catch (error) {
            return null;
        }
    } else {
        return null;
    }
}
export const timeToMysql = (fecha) => {
    if(fecha !== null && fecha !== undefined && fecha !== '0000-00-00 00:00:00') {
        try{
            return moment(fecha).format('HH:mm:ss')
        } catch (error) {
            return null;
        }
    } else {
        return null;
    }
}
