import { Box, Typography } from '@material-ui/core';
import { ta } from 'date-fns/locale';
import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";
import { getTareasSiguienteSemana } from '../../../services/tarea';

import { makeStyles } from '@material-ui/core/styles';
import noResult from '../../../assets/img/no-result.png'
import { PRIMARY_COLOR } from '../../../constants/colores'
import TodoList from '../../../components/TodoList'
import LoadingComponent from '../../../components/Loading';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    }
}));

function SiguienteSemanaContent() {
    const classes = useStyles();

    const [todos, setTodos] = React.useState([]);
    const [loading, setLoading] = React.useState(false)
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {
        setLoading(true)

        const usuario_id = usuarioSeleccionado.id

        getTareasSiguienteSemana({ usuario_id }).then((response) => {
            response && setTodos(response.tareas)
            setLoading(false)
        })
    }, [])

    const Contenido = () => {
        return (
            todos.length > 0 ?
                <>
                    <Typography style={{ marginTop: 16, marginLeft: 16 }} variant="h6" component="h6">Siguiente semana</Typography>
                    <TodoList
                        items={todos}
                    />
                </>
                :
                <EmptyComponent />
        )
    }
    const EmptyComponent = () => {
        return (
            <Box className={classes.emptyContainer}>
                <img className={classes.emptyImage} src={noResult} alt="No se han encontrado resultados" />
                <h1 style={{ color: PRIMARY_COLOR }}>Todavía no tienes tareas creadas!!</h1>
            </Box>
        )
    }

    return (
        <div className={classes.container}>
            {
                (loading ?
                    <LoadingComponent isFullScreen={true} />
                    :
                    <Contenido />
                )
            }
        </div>
    )
}

export default SiguienteSemanaContent
