import React from "react";
import { List, Paper } from "@material-ui/core";

import TodoListItem from "./TodoListItem";

import {
    ListItem,
    Checkbox,
    IconButton,
    ListItemText,
    ListItemSecondaryAction
} from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';



const TodoList = React.memo(({ tarea_padre_id = '-1', items }) => (
    <>
        {
            items.length > 0 && (
                <Paper style={{ margin: 16 }}>
                    <List style={{ overflow: "scroll" }}>
                        {items.map((todo, idx) => (
                            <TodoListItem
                                {...todo}
                                id={todo.id}
                                text={todo.descripcion}
                                fecha = {todo.fecha}
                                fecha_mostrar={todo.fecha_mostrar}
                                key={`TodoItem.${idx}`}
                                divider={idx !== items.length - 1}
                                showLink={tarea_padre_id == '-1' ? true : false}
                                checked={todo.is_checked}
                                dias_transcurridos={todo.dias_transcurridos}
                            // onButtonClick={() => onItemRemove(idx)}
                            // onCheckBoxToggle={() => onItemCheck(todo.id)}
                            />
                        ))}
                    </List>
                </Paper>
            )
        }
    </>
));

export default TodoList;
